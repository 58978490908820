<template>
  <div class="mysound-popup">
    <div class="web">
      <div class="mysound-header2">
        <div class="mysound-header2-title">
          <img src="/media/images/icon_back_40.svg" alt="" @click="back" />
          <p>음성 녹음</p>
        </div>
        <div class="mysound-close" @click="closePopup">
          <img src="/media/images/icon_close_40.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="mo-mysound-header">
        <div class="mo-mysound-back" @click="back">
          <img src="/media/images/icon_back_24.svg" alt="" />
        </div>
        <div>
          <p>음성 녹음</p>
        </div>
        <div class="mo-mysound-close" @click="closePopup">
          <img src="/media/images/icon_close_24.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="mysound-detail-content" v-if="finalPage">
      <div class="content-title mb-16">
        <p>음성 녹음은 최대 1분까지 등록할 수 있어요.</p>
      </div>

      <div class="content-circle-area mb-16">
        <div class="circle-box">
          <div class="circle-line">
            <div
              class="recording-btn style-cursor"
              v-if="!recodeStatus"
              :style="{ 'pointer-events': file == null ? '' : 'none', opacity: file == null ? '1' : '0.3' }"
              @click="start"
            >
              <!-- 녹음 -->
              <img src="/media/img/mysound/btn_record_1129.svg" alt="" />
            </div>

            <div class="recording-btn-stop style-cursor" v-else @click="stop">
              <!-- 정지 -->
              <img src="media/img/mysound/btn_record_stop.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="btn-area">
        <div class="btn-area-box">
          <div class="control-btn" v-if="!recodeStatus && !replayStatus && file != null" @click="replay">
            <!-- 다시 듣기 -->
            <img src="/media/img/mysound/btn_record_play.svg" alt="" />
          </div>

          <div class="control-btn" v-if="!recodeStatus && replayStatus && file != null" @click="replayStop">
            <!-- 일시 정지 -->
            <img src="/media/img/Btn_Pause.svg" alt="" style="width: 25px; height: 25px;" />
          </div>

          <div class="control-btn" v-if="!recodeStatus && file != null" @click="reset">
            <!-- 리셋 -->
            <img src="media/img/mysound/btn_record_reset.svg" alt="" />
          </div>
        </div>
      </div>
      <div style="display: none;">
        <!-- <div> -->
        <!-- 레코드 플레이어 -->
        <audio-recorder ref="recorder" :after-recording="setRecorded" :before-recording="startRecord" />
      </div>
    </div>

    <div class="mysound-detail-content" v-else>
      <div class="content-title mb-16">
        <p>녹음한 오디오 파일명을 설정해주시겠어요?</p>
      </div>

      <div>
        <input
          type="text"
          v-model="soundTitle"
          maxlength="10"
          style="width: 100%;"
          placeholder="최대 10자까지 입력 가능합니다."
        />
      </div>
    </div>

    <div class="mysound-footer">
      <div
        class="common-btn-style"
        @click="nextPage"
        :style="{ 'pointer-events': file != null ? '' : 'none', opacity: file != null ? '1' : '0.3' }"
        v-if="finalPage"
      >
        <p>다음</p>
      </div>

      <div
        class="common-btn-style"
        v-else
        :style="{ 'pointer-events': soundTitle !== '' ? '' : 'none', opacity: soundTitle !== '' ? '1' : '0.3' }"
        @click="saveFile"
      >
        <p>완료</p>
      </div>
    </div>

    <UploadConfirm v-if="uploadStatus" @closeUploadConfirm="refresh"></UploadConfirm>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import UploadConfirm from '@/components/modal/Player/mysound/confirm/UploadConfirm.vue';
import Loading from '@/components/modal/Player/mysound/etc/Loading.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'mysoundVoice',

  components: {
    UploadConfirm,
    Loading
  },

  data() {
    return {
      // 녹음 상태
      recodeStatus: false,

      // 리플레이
      replayStatus: false,

      // 파일
      file: null,

      // 시간
      second: 55,
      timer: 0,
      storageTimer: 0,

      // 페이지 변경
      finalPage: true,

      // 파일명 지정
      soundTitle: '',

      // 서클
      circleInterval: null,

      // 로딩
      loading: false,

      // 팝업
      uploadStatus: false
    };
  },

  methods: {
    start() {
      // 녹음 시작
      this.recodeStatus = true;
      $('.ar-recorder .ar-icon__lg').click();
      this.setIntervalCircle();
    },

    async stop() {
      // 녹음 정지
      const $this = this;
      $this.replayStatus = false;

      function stop() {
        $('.ar-recorder__stop').click();
      }

      function changeStatus() {
        $('.ar-records__record').click();
        $this.recodeStatus = false;
      }

      await stop();
      clearInterval($this.circleInterval);
      await changeStatus();
    },

    async replay() {
      // 리플레이 시작
      const $this = this;
      let time = null;

      function getTime() {
        time = document.getElementsByClassName('ar-player__time')[1].innerHTML;
        let minute = parseInt(time.substring(0, 2));
        let second = parseInt(time.substring(3));
        if (minute > 0) {
          minute = minute * 60;
        }

        second += minute;
        $this.second = second;
      }

      function changeStatus() {
        $this.setIntervalCircleReplay();
        $this.replayStatus = true;
      }

      function play() {
        $('#play').click();
      }

      await getTime();
      await changeStatus();
      await play();
    },

    replayStop() {
      // 리플레이 정지
      $('#play').click();
      clearInterval(this.circleInterval);
      this.storageTimer = this.timer;
      this.replayStatus = false;
    },

    async reset() {
      // 리셋
      const $this = this;

      // 리플레이 정지
      function stop() {
        $('.ar-records__record').click();
        $this.replayStatus = false;
      }

      function deleteFile() {
        // 파일 삭제
        $this.file = null;
        $('.ar-records__record')
          .first()
          .remove();
        document.querySelector('.ar-player audio').src = '';
      }

      function changeStatus() {
        // 시간 초기화
        $this.second = 55;
        $this.timer = 0;
        $this.storageTimer = 0;

        // 서클 초기화
        const circle = document.getElementById('mysound-circle');
        const progressPx = circle.style.strokeDasharray;
        const progress = Number(progressPx.replace('px', ''));
        circle.style.strokeDashoffset = progress;

        // 상태 초기화
        $this.recodeStatus = false;
      }

      await stop();
      await deleteFile();
      await changeStatus();
    },

    setRecorded(e) {
      // 파일 저장
      console.log('e : ', e);

      this.file = e.blob;
    },

    startRecord(e) {
      // 파일 초기화
      if (e != null) {
        e = {};
      }
    },

    nextPage() {
      clearInterval(this.circleInterval);
      this.finalPage = false;
    },

    async saveFile() {
      this.loading = true;
      const userId = VueCookies.get('userId');
      const fileToUpload = this.file;

      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('fileToUpload', fileToUpload);

      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      const res = await axios.post('/api/mysound/uploadChosenFile', formData, { headers }).catch(() => {
        this.loading = false;
      });
      const fileOnServer = res.data.result.fileOnServer;
      const playtime = res.data.result.playtime;
      const spaceId = VueCookies.get('spaceId');
      const soundTitle = this.soundTitle;

      const res2 = await axios
        .post('/api/mysound/registMySoundByFile', { userId, spaceId, soundTitle, fileOnServer, playtime }, { headers })
        .catch(() => {
          this.loading = false;
        });
      if (res2.data.result) {
        this.loading = false;
        this.uploadStatus = true;
      }
    },

    refresh() {
      this.uploadStatus = false;
      this.$emit('refresh');
    },

    setIntervalCircle() {
      this.timer = 0;

      this.circleInterval = setInterval(() => {
        if (this.second - this.timer !== 0) {
          this.timer++;
          if (this.timer === 55) {
            this.stop();
          }
          const circle = document.getElementById('mysound-circle');
          if (circle !== undefined) {
            const progressPx = circle.style.strokeDasharray;
            const progress = Number(progressPx.replace('px', ''));
            const number = progress / this.second;
            const percent = number * this.timer;
            circle.style.strokeDashoffset = progress - percent;
          }
        } else {
          this.stop();
        }
      }, 1000);
    },

    setIntervalCircleReplay() {
      const $this = this;
      this.timer = this.storageTimer;

      this.circleInterval = setInterval(() => {
        if (this.second - this.timer !== 0) {
          this.timer++;
          if (this.timer === this.second) {
            clearInterval($this.circleInterval);
            $this.replayStatus = false;
            $this.storageTimer = 0;
          }
          const circle = document.getElementById('mysound-circle');
          if (circle !== undefined) {
            const progressPx = circle.style.strokeDasharray;
            const progress = Number(progressPx.replace('px', ''));
            const number = progress / this.second;
            const percent = number * this.timer;
            circle.style.strokeDashoffset = progress - percent;
          }
        } else {
          clearInterval($this.circleInterval);
          $this.replayStatus = false;
          $this.storageTimer = 0;
        }
      }, 1000);
    },

    back() {
      if (this.finalPage) {
        clearInterval(this.circleInterval);
        this.$emit('closeAppendPopup');
      } else {
        this.finalPage = true;
        this.soundTitle = '';
      }
    },

    closePopup() {
      clearInterval(this.circleInterval);
      this.$emit('closePopup');
    }
  },

  created() {
    const interval = setInterval(() => {
      if (document.querySelector('.circle-box') != null) {
        clearInterval(interval);

        circleProgressBar();

        window.addEventListener('resize', () => {
          circleProgressBar();
        });
      }
    }, 300);

    function circleProgressBar() {
      const box = document.querySelector('.circle-box');

      if (box != null) {
        if (box.querySelector('#progressPosition') != null) {
          box.removeChild(box.querySelector('#progressPosition'));
        }
      }

      const div = document.createElement('div');
      div.id = 'progressPosition';
      div.style.position = 'absolute';
      div.style.top = '0';
      div.style.left = '0';
      div.style.border = '0';
      div.style.zIndex = '5';

      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
      svg.setAttribute(`viewBox`, `0 0 ${box.clientWidth} ${box.clientHeight}`);
      svg.setAttribute('id', 'circleProgress');
      svg.setAttribute('width', box.clientWidth);
      svg.setAttribute('height', box.clientHeight);
      svg.style.transform = 'rotate(-90deg)';

      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      circle.id = 'mysound-circle';
      circle.style.fill = 'none';
      circle.style.stroke = '#ff2d55';
      circle.style.strokeWidth = '4px';
      circle.style.strokeDasharray = 2 * Math.PI * (box.clientHeight / 2);
      circle.style.strokeDashoffset = 2 * Math.PI * (box.clientHeight / 2);
      circle.setAttribute('cx', box.clientWidth / 2);
      circle.setAttribute('cy', box.clientHeight / 2);
      circle.setAttribute('r', box.clientWidth / 2 - parseInt(circle.style.strokeWidth) + 1);

      svg.appendChild(circle);
      div.appendChild(svg);
      box.appendChild(div);
    }
  }
};
</script>

<style src="@/assets/css/mysound.css"></style>
<style scoped>
.content-title {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;

  text-align: center;
  letter-spacing: -0.01em;

  color: #ffffff;
}

.content-circle-area {
  width: 100%;
  height: 250px;

  display: flex;
  justify-content: center;
}

.circle-box {
  position: relative;
  width: 250px;
  height: 250px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.circle-line {
  position: absolute;
  width: 245px;
  height: 245px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.btn-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-area-box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.recording-btn {
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.recording-btn img {
  width: 100%;
  height: 100%;
}

.recording-btn-stop {
  position: absolute;
  width: 90%;
  height: 90%;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0.16);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.recording-btn-stop img {
  width: 36px;
  height: 36px;
}

.control-btn {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
</style>
